.style-tag {
  background-color: black;
  color: white;
  margin: 0.25rem 0.5rem 0.25rem 0;
  padding: 0.25rem 0.5rem 0.25rem 1rem;
  border-radius: 2rem;
  display: flex;
  align-content: center;
  align-items: center;
  white-space: nowrap;
  display: inline-block;
  transition: all 0.2s ease-in-out;
}

.style-tag.hidden {
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
}

.style-tag > span {
  padding-right: 1rem;
}