@import url(https://fonts.googleapis.com/css?family=Roboto|Roboto+Condensed&display=swap);
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Roboto Condensed';
  /* overflow: hidden; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button {
  font-family: 'Roboto Condensed';
}

.App {
  height: 100vh;
}

.pulse {
  /* animation: Pulse 2s linear infinite; */
}

@-webkit-keyframes Pulse {
  0%, 100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.96);
            transform: scale(0.96);
  }
}

@keyframes Pulse {
  0%, 100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.96);
            transform: scale(0.96);
  }
}
.root {
  padding: 1em;
}

.switch-wrapper {
  position: relative;
}

.switch-wrapper > * {
  width: 100%;  
}
 
.switch-wrapper > div {
  position: absolute;
}

.MuiSvgIcon-root.animated {
	-webkit-transform: scale(1);
	        transform: scale(1);
  -webkit-animation: pulse 0.5s;
          animation: pulse 0.5s;
}

@-webkit-keyframes pulse {
	0% {
		-webkit-transform: scale(1);
		        transform: scale(1);
	}

	50% {
		-webkit-transform: scale(0.5);
		        transform: scale(0.5);
	}

	100% {
		-webkit-transform: scale(1);
		        transform: scale(1);
	}
}

@keyframes pulse {
	0% {
		-webkit-transform: scale(1);
		        transform: scale(1);
	}

	50% {
		-webkit-transform: scale(0.5);
		        transform: scale(0.5);
	}

	100% {
		-webkit-transform: scale(1);
		        transform: scale(1);
	}
}

.container {
  flex: 1 1;
  display: flex;
  padding: 0 2rem;
}

.circle {
  border-radius: 50%;
  text-align: center;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  outline: none;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}

.circle[selected="true"] {
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
  background-color: black;
  opacity: 1;
  color: white;
}

.stamp {
  position: absolute;
  width: 150px;
  height: 150px;
}

.stamp > .corner-tl {
  top: 0;
  left: 0;
}

.stamp > .corner-tr {
  top: 0;
  right: 0;
}

.tag-text {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  pointer-events: none;
}
.style-tag {
  background-color: black;
  color: white;
  margin: 0.25rem 0.5rem 0.25rem 0;
  padding: 0.25rem 0.5rem 0.25rem 1rem;
  border-radius: 2rem;
  display: flex;
  align-content: center;
  align-items: center;
  white-space: nowrap;
  display: inline-block;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.style-tag.hidden {
  -webkit-transform: scale(0);
  transform: scale(0);
}

.style-tag > span {
  padding-right: 1rem;
}
